<script setup lang="ts">
import {
	LazyComponentMatrixBlocksAboutNklIntro,
	LazyComponentMatrixBlocksCasinoCta,
	LazyComponentMatrixBlocksKamagurka,
	LazyComponentMatrixBlocksContact,
	LazyComponentMatrixBlocksProfiles,
	LazyComponentMatrixBlocksFeatured,
	LazyComponentMatrixBlocksTheme,
	LazyComponentMatrixBlocksHomeIntro,
	LazyComponentMatrixBlocksSeries,
} from '#components'
import type { ComponentMatrix_MatrixField } from '~/generated/operations'

const props = defineProps<{
	blocks: ComponentMatrix_MatrixField[]
}>()

const blockComponents: Record<string, any> = {
	aboutNklIntro: LazyComponentMatrixBlocksAboutNklIntro,
	homeIntro: LazyComponentMatrixBlocksHomeIntro,
	theme: LazyComponentMatrixBlocksTheme,
	series: LazyComponentMatrixBlocksSeries,
	featured: LazyComponentMatrixBlocksFeatured,
	profiles: LazyComponentMatrixBlocksProfiles,
	contact: LazyComponentMatrixBlocksContact,
	kamagurka: LazyComponentMatrixBlocksKamagurka,
	casinoCta: LazyComponentMatrixBlocksCasinoCta,
}
</script>

<template>
	<component
		v-for="block in props.blocks"
		:is="blockComponents[block.typeHandle]"
		:block="block"
		:critical="block.typeHandle === 'homeIntro'"
	/>
</template>
