<script setup lang="ts">
import { type ComponentMatrix_MatrixField } from '~/generated/operations'

const page = inject('page')
</script>
<template>
	<div>
		<div v-logo-toggler class="h-50px" />
		<ComponentMatrix
			:blocks="page.componentMatrix as ComponentMatrix_MatrixField[]"
		/>
	</div>
</template>
